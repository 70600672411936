import React from "react";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/about/Content";
import CTA from "../sections/nightshift/CTA";

const Story = () => {
  return (
    <>
      <Helmet>
        <title>NightShift | Story</title>
      </Helmet>
      <PageWrapper>
        <Hero title="Our Story">
          Placeholder for Story 
        </Hero>
        <Content />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default Story;
